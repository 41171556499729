<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="me-2" text icon :color="cor.active.P" :dark="isDark" v-bind="attrs" v-on="on">
        <span v-i:duo#gear#26 v-c:K></span>
      </v-btn>
    </template>
    <v-card class="mt-1">
      <v-list dense class="" :color="hexShades(cor.active.B,0.05)">
        <v-list-item @click="goto(item.path)" v-for="(item, index) in links" v-bind:key="index">
          <v-list-item-content class="">
            <v-list-item-title class="valign my-0 py-0" v-c:K>
              <duoicon class="fs-16pt me-2" :icon="item.icon" :color="cor.active.K" />
              <span class="pt-1 f-lato fs-11pt">{{item.nome}}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name: 'topmenuitemadmin',
  props: {},
  data(){
    return {
      user: {},
      links: [
        // { nome: "toggleDark", icon: "brightness", path: "*toggleDark*" },
        { nome: "Usuários", icon: "user", path: "/usuarios" },
      ]
    }
  },
  created() {
    console.log("Top menu item ADMIN Created");
  },
  mounted() {
    //var self = this;
    console.log("Top menu item ADMIN Mounted")
    //self.user = self.$session.get('user');
  },
  methods: {

    logout() {
      console.log("Cliquei logout menu...");
      this.$logout();
    },

    goto(txtpath) {
      if(txtpath == "*toggleDark*") {
        this.toggleDark();
        return;
      }
      this.$router.push( { path: txtpath }).catch(err => { console.log(err) });
    }

  }
}

</script>

<style scoped>

.valign {
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>
