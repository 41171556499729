<template>
  <span>
    <v-dialog v-model="dialog" width="800">
      <v-card v-bg:b class="pb-3">
        <dialogtoolbar @close="dialog = false" />
        <v-row class="mx-1 mt-1" v-if="user != null">
          <v-col cols="12" class="">
            <v-card v-bg:B class="mt-4">
              <v-list-item class="mb-0 pb-0 mt-n4">
                <v-list-item-avatar size="32" class="mt-0">
                  <v-avatar size="32">
                    <img :src="user.urlFoto" class="rounded-circle" alt="" style="">
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="text-left">
                  <v-list-item-title class="">
                    <p class="mb-0 fs-8pt f-roboto">
                      {{user.nome}}
                      <span class="text-muted fs-10pt f-roboto">
                        <b-icon icon="envelope-fill" class="ms-2 me-1"></b-icon>
                        {{user.email}}
                      </span>
                      <span class="text-muted fs-10pt f-lato">
                        <b-icon icon="telephone-fill" class="ms-2 me-1"></b-icon>
                        {{user.telefone}}
                      </span>
                      <v-btn class="me-2 text-nonecase" :color="cor.active.V" outlined @click="liberar(user)" x-small>liberar</v-btn>
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p class="fs-8pt f-roboto mt-1">
                      {{user.cargo}}
                      <!-- <tagsarea :siglas="item.Areas"/> -->
                      <span class="badge ms-2 fs-7pt f-roboto fw-400 text-dark" style="backgroundColor: #f0f0f0; vertical-align: bottom;">
                        {{user.ies}} - {{user.regional}} - {{user.unidade}}
                      </span>
                      <span class="ms-3 f-sanspro fw-800">Formação:</span> <span class="ms-1">{{user.formacao.titulacao}} - {{user.formacao.area}}</span>
                      <!-- <tagsarea :siglas="item.Areas"/> -->
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card class="mt-2 p-2 px-4">
              <div class="" v-for="(prof,key,index) in profsParecidos" :key="index">
                <v-list-item class="mb-0 pb-0 mt-n4">
                  <v-list-item-avatar size="32" class="mt-0">
                    <v-avatar size="32">
                      <img :src="prof.urlFoto" class="rounded-circle" alt="" style="">
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="text-left">
                    <v-list-item-title class="">
                      <p class="mb-0 fs-8pt f-roboto">
                        {{prof.nome}}
                        <span class="text-muted fs-10pt f-roboto">
                          <b-icon icon="envelope-fill" class="ms-2 me-1"></b-icon>
                          {{prof.email}}
                        </span>
                        <span class="text-muted fs-10pt f-lato">
                          <b-icon icon="telephone-fill" class="ms-2 me-1"></b-icon>
                          {{prof.telefone}}
                        </span>
                      </p>
                      <p class="m-0 pt-1 fs-8pt f-roboto" v-if="prof.emails != null">
                        <span class="me-1 fs-8pt f-roboto" v-for="item in prof.emails" :key="item">
                          {{item}}
                        </span>
                      </p>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <p class="fs-8pt f-roboto mt-1">
                        {{prof.cargo}}
                        <!-- <tagsarea :siglas="item.Areas"/> -->
                        <span class="badge ms-2 fs-7pt f-roboto fw-400 text-dark" style="backgroundColor: #f0f0f0; vertical-align: bottom;">
                          {{prof.ies}} - {{prof.regional}} - {{prof.unidade}}
                        </span>
                        <span class="ms-3 f-sanspro fw-800">Formação:</span>
                        <span class="ms-1">{{prof.formacao.titulacao}} - {{prof.formacao.area}}</span>
                        <span class="ms-3 f-sanspro fw-800">CPF:</span>
                        <span class="ms-1">{{prof.cpf}}</span>
                        <!-- <tagsarea :siglas="item.Areas"/> -->
                      </p>
                      <p class="fs-8pt f-roboto mt-1">
                        <v-btn class="me-2 text-nonecase" color="primary" outlined @click="importarEmail(prof)" x-small>importar email</v-btn>
                        <v-btn class="me-2 text-nonecase" color="primary" outlined @click="importarCPF(prof)" x-small>importar CPF</v-btn>
                        <v-btn class="me-2 text-nonecase" color="primary" outlined @click="importarTel(prof)" x-small>importar TEL</v-btn>
                        <v-btn class="me-2 text-nonecase" color="primary" outlined @click="importarFormacao(prof)" x-small>importar Formação</v-btn>
                        <v-btn class="me-2 text-nonecase" color="primary" outlined @click="importarIES(prof)" x-small>importar IES</v-btn>
                      </p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-n4"></v-divider>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import dialogtoolbar from "./dialogToolBar.vue";

export default {
  name: "dialogliberar",
  components: { dialogtoolbar },
  props: {
    color: { default: "#000000", type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      user: null,
      profsByNome: {},
      profsParecidos: {},
    }
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
      var ret = snapshot.val();
      self.profsByNome = {};
      for(var key in ret) {
        self.profsByNome[ret[key].nome] = ret[key];
      }
      console.log("self.profsByNome",self.profsByNome);
      console.log("qtde self.profsByNome",Object.keys(self.profsByNome).length);
    });

  },
  methods:{

    build() {
    },

    show(user) {
      console.log("show");
      console.log("user",user);
      this.user = user;
      this.profsParecidos = {};
      for(var key in this.profsByNome) {
        var nome1 = this.user.nome.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        var nome2 = this.profsByNome[key].nome.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        var similar = stringSimilarity.compareTwoStrings(nome1,nome2);
        if(similar > 0.8) {
          this.profsParecidos[this.profsByNome[key].id] = this.profsByNome[key];
        } else {
          if(nome1.split(" ")[0].trim() == nome2.split(" ")[0].trim()) {
            this.profsParecidos[this.profsByNome[key].id] = this.profsByNome[key];
          }
        }
      }
      console.log("this.profsParecidos",this.profsParecidos);
      this.dialog = true;
    },

    liberar(user) {
      console.log("liberar",user);
      var updates = {};
      updates["/usuarios/pessoasPendentes/"+user.id+"/liberado"] = true;
      updates["/usuarios/pessoas/"+user.id] = user;
      rdb.ref().update(updates);
      this.toastS("Usuário LIBERADO com sucesso.");
    },

    importarEmail(prof) {
      console.log("importarEmail");
      console.log("this.user",this.user);
      console.log("prof",prof);
      var updates = {};
      var emails = prof.emails;
      if(emails == undefined) {
        emails = [ prof.email ];
      }
      emails.push(this.user.email);
      updates["/usuarios/pessoas/"+prof.id+"/emails"] = emails;
      var newKey = rdb.ref('/usuarios/pessoasEmail/').push().key;
      var elem = {
        id: newKey,
        email: this.user.email,
        userID: prof.id,
        userNome: prof.nome,
      }
      updates["/usuarios/pessoasEmail/"+newKey] = elem;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("agora já elvis");
    },

    importarCPF(prof) {
      console.log("importarCPF");
    },

    importarTel(prof) {
      console.log("importarTel");
      console.log("this.user",this.user);
      console.log("prof",prof);
      var updates = {};
      updates["/usuarios/pessoas/"+prof.id+"/telefone"] = this.user.telefone;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("agora já elvis");

    },

    importarFormacao(prof) {
      console.log("importarFormacao");
      console.log("this.user",this.user);
      console.log("prof",prof);
      var updates = {};
      updates["/usuarios/pessoas/"+prof.id+"/formacao"] = this.user.formacao;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("agora já elvis");
    },

    importarIES(prof) {
      console.log("importarIES");
      var self = this;
      console.log("this.user",this.user);
      console.log("prof",prof);
      var updates = {};
      updates["/usuarios/pessoas/"+prof.id+"/ies"] = this.user.ies;
      updates["/usuarios/pessoas/"+prof.id+"/regional"] = this.user.regional;
      updates["/usuarios/pessoas/"+prof.id+"/unidade"] = this.user.unidade;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("agora já elvis");

    },

    limpa() {
      console.log("importarIES");
      var self = this;

      rdb.ref('/usuarios/pessoasPendentes').once('value', function(snapshot) {
        var pendentes = snapshot.val();
        console.log("self.pendentes",pendentes);
        console.log("qtde self.pendentes",Object.keys(pendentes).length);
        var updates = {};
        for(var key in pendentes) {
          updates["/usuarios/pessoas/"+pendentes[key].id] = null;
          updates["/usuarios/pessoasPendentes/"+pendentes[key].id+"/liberado"] = null;
        }
        console.log("updates",updates);
        rdb.ref().update(updates);
        console.log("agora já elvis");

      });
    }



  }
}
</script>

<style scoped>
</style>
