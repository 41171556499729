<template>
  <div class="">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text icon class="me-1" v-bind="attrs" v-on="on">
          <avatarcon :user="userLog" :size="38"/>
          <!-- <v-avatar size="38">
            <img :src="userLog.urlFoto" alt="" :key="force">
          </v-avatar> -->
        </v-btn>
      </template>
      <v-card class="mx-auto pb-2" max-width="270" outlined elevation="5" v-bg:B>
        <v-list-item three-line class="mb-n2 pb-0">
          <v-list-item-avatar tile size="56" class="me-1 mt-3 mb-0 pb-0">
            <avatarcon :user="userLog" :size="56"/>
            <!-- <v-avatar color="secondary" size="56">
              <img :src="userLog.urlFoto" alt="John">
            </v-avatar> -->
          </v-list-item-avatar>
          <v-list-item-content class="px-0 ms-2 mb-0 pb-0" v-c:K>
            <v-list-item-title class="mt-0 fw-500 f-raleway fs-10pt">
              <p class="mb-1">{{userLog.nome}}</p>
              <p class="f-roboto fw-200 mt-0 fs-9pt">{{userLog.email}}</p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-0 pt-0"></v-divider>
        <v-list dense class="mb-0 pb-0" v-bg:B>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content class="">
                <v-list-item-title class="valign my-0 py-0" v-c:K @click="$router.push( { name: 'profile' });">
                  <duoicon class="fs-14pt me-2" icon="user" :color="cor.active.K" />
                  <span class="pt-1 f-raleway fs-11pt fw-300">Meu perfil</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="">
                <v-list-item-title class="valign my-0 py-0" v-c:K @click="$refs.formprofileref.show()">
                  <span class="fs-14pt me-2" v-i:duo#user :color="cor.active.K" />
                  <span class="pt-1 f-raleway fs-11pt fw-300">atualizar perfil</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-0 pt-0"></v-divider>
            <v-list-item class="mb-0 pb-0">
              <v-list-item-content class="mb-0 pb-0" @click="logout">
                <v-list-item-title class="valign my-0 py-0" v-c:K>
                  <span v-i:ic#exit-to-app class="mr1"></span>
                  <span class="pt-1 f-raleway fs-11pt fw-300 my-0 py-0">Sair</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <formprofile ref="formprofileref" />
  </div>
</template>

<script>
import formprofile from "./formProfile.vue"
import { eventBus } from "@/main.js";
import avatarcon from "./avatar-con.vue"

export default {
  name: 'topmenuitemuser',
  props: {
  },
  components: { formprofile, avatarcon },
  data(){
    return {
      force: 0,
    }
  },
  created() {
    //console.log("Top menu item user Created");
  },
  mounted() {
    var self = this;
    //console.log("Top menu item user Mounted")
    //self.user = self.$session.get('user');
    eventBus.$on("atualizaUsuario", function() {
      self.force++;
      self.$forceUpdate();
    });

  },
  methods: {

  }
}

</script>

<style scoped>
.shadow {
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.3), 2px 6px 20px 2px rgba(0, 0, 0, 0.19);
}
</style>
