<template>
  <div class="">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar :color="cor.active.b">
          <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
            Recuperação de acesso
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <div class="my-3 py-0">
            <v-autocomplete class="my-0 py-0" dense v-model="selecionado" :items="pessoas" chips color="blue-grey lighten-2" item-text="nome" item-value="id">
              <template v-slot:selection="data">
                <v-chip class="my-1 py-0" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                  <v-avatar left size="20">
                    <v-img :src="data.item.urlFoto"></v-img>
                  </v-avatar>
                  <span class="ms-2 fw-300 f-raleway fs-8pt">{{ data.item.nome.substring(0,14) }}</span>
                </v-chip>
              </template>
              <template v-slot:item="data">
                <div class="my-0 py-0" style="max-height: 10px;">
                  <v-avatar class="left my-0 py-0" size="26">
                    <img :src="data.item.urlFoto">
                  </v-avatar>
                  <span class="left fw-300 f-raleway fs-10pt ms-2 mt-1">
                    {{data.item.nome}}
                  </span>
                </div>
              </template>
              <template v-slot:label>
                <span class="fs-10pt f-raleway">
                  Será que seu <strong>nome</strong> já está cadastrado? Busque aqui
                </span>
                <v-icon style="vertical-align: middle">mdi-file-find</v-icon>
              </template>
            </v-autocomplete>
          </div>
          <v-card class="mx-auto p-2" width="400" v-if="selecionado != null">
            <p class="m-0 p-0 text-center">
              <span class="f-sanspro fw-600">Seu email é:</span>
              <span class="ms-2 f-raleway fs-11pt">{{pessoas.findID(selecionado).email}}</span>
              <div class="text-center" v-if="pessoas.findID(selecionado).emails != undefined">
                <span class="ms-2 f-raleway fs-11pt" v-for="(item,index) in pessoas.findID(selecionado).emails" :key="index">
                  {{item}}
                </span>
              </div>
            </p>
          </v-card>
          <v-card class="px-1 py-0 m-0 mb-4">
            <div class="clear m-0 px-1">
              <div class="p-0">
                <v-switch v-model="optionCad" color="indigo" value="indigo" hide-details>
                  <template v-slot:label class=" m-0 p-0">
                    <span class="ms-1 fs-10pt f-raleway">Obrigado, mas não achei meu nome e quero me cadastrar</span>
                    <i v-i:duo#smiley#20 class="btn-icon-right"></i>
                  </template>
                </v-switch>
              </div>
              <div class="clear m-0 p-0"></div>
              <div class="mt-3" v-if="optionCad">
                <v-row>
                  <v-col cols="12" class="mt-2 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome do usuário</p>
                    <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense label="Nome" required placeholder="Digite o nome" hint="Por gentileza, digite seu nome completo"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Email do usuário</p>
                    <v-text-field v-model="formData.email" :rules="[rules.required, rules.email]" solo dense label="Email" required placeholder="Digite o email"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Telefone do usuário</p>
                    <v-text-field v-model="formData.telefone" solo dense label="Telefone" required placeholder="Digite o telefone"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12" class="mt-0 mb-1 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Áreas de Integração Curricular</p>
                    <treeselect v-if="areas.length>0" v-model="areasSel" multiple :normalizer="areaNormalizer" :options="areas" placeholder="Filtrar por área">
                      <div slot="option-label" slot-scope="{ node }">
                        <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
                      </div>
                      <div slot="value-label" slot-scope="{ node }">
                        <span v-c:P class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,10)}}</span>
                      </div>
                    </treeselect>
                  </v-col>
                  <v-col cols="12" lg="6" class="mt-3 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Formação</p>
                    <v-select solo dense v-model="formData.formacao.titulacao" :items="itensFormacao"></v-select>
                  </v-col>
                  <v-col cols="12" lg="6" class="mt-3 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Área de Formação</p>
                    <v-text-field v-model="formData.formacao.area" solo dense label="Área de formação acadêmica" placeholder="Digite área de formação acadêmica"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" lg="4" class="mt-n2 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Unidade</p>
                    <v-select solo dense v-model="formData.unidade" :items="itensUnidade"></v-select>
                  </v-col> -->
                  <v-col cols="12" lg="6" class="mt-n2 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Regional</p>
                    <v-select solo dense v-model="formData.regional" :items="['SP','MG / GO', 'NE', 'SUL']"></v-select>
                  </v-col>
                  <v-col cols="12" lg="6" class="mt-n2 mb-0 py-0">
                    <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">IEs</p>
                    <v-select solo dense v-model="formData.ies" :items="itensIES"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="text-right p-0 m-0">
                      <v-btn class="right mt-n2 me-2" outlined x-small @click="adicionarProf">
                        Enviar solicitação de cadastro
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Solicitação enviada com sucesso!
        </v-card-title>
        <v-card-text class="mt-2">
          <svgaccess />
          <p class="f-raleway mt-2 mb-0">
            Sua solicitação será <strong>avaliada em breve</strong> e um email será enviado com sua senha e orientações de acesso.
          </p>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="cor.active.P" v-c:P small outlined @click="dialog2 = false">
            <i v-i:duo#smiley#20 class="btn-icon-left"></i>
            Obrigado! Vou aguardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import svgaccess from "@/components/svgs/svg-access.vue"

var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: "dialogrecuperacao",
  components: { svgaccess },
  props: [],
  computed: { },
  data(){
    return {
      loading: false,
      texto: "",
      dialog: false,
      dialog2: false,
      selecionado: null,
      pessoas: [],
      optionCad: false,
      areas: [],
      areasObj: [],
      areasSel: {},
      setores: [],
      setoresObj: {},
      setorSel: [],
      equipes: [],
      equipesObj: {},
      equipeSel: [],
      itensFormacao: ['Graduado', 'Especialista', 'Mestre', 'Doutor(a)', 'Pós Doutor(a)'],
      itensUnidade: [
        "Aimorés","Anita Garibaldi","Barreiro","Betim","Blumenau","Bom Despacho","Butantã","Catalão",
        "Conselheiro Lafaiete","Contagem","Cristiano Machado","Divinópolis","Florianópolis","Guanambi","Guarulhos","Itabira","Itumbiara","Jabaquara","Jacobina","Jaraguá","Jataí","Lagarto","Liberdade","Linha Verde","Milton Viana","Mooca","Paripiranga","Paulista","Pedra Branca","Pouso Alegre","Santana","Santo Amaro","São Bento do Sul",
        "São Bernardo do Campo","Senhor do Bomfim","Sete Lagoas","Tubarão","Uberlândia","Vila Leopoldina","Vila Matias"
      ],
      itensIES: [ "AGES","UNA","UNIBH","UNICURITIBA","USJT","UNISOCIESC","UNISUL","UNIFG", "UAM", "UNIFACS", "FPB", "UNIFG-PE", "UNP", "IBMR", "UNIRITTER", "FADERGS"],
      formData: {
        nome: "", cargo: "Educador(a)", urlCapa: "https://placeimg.com/1200/200/nature", urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/responsaveis%2Fdummy.png?alt=media&token=f785c7d3-f03c-4311-acec-983e2f9e9103",
        formacao: { area: "", titulacao: "Graduado" }, grandeArea: 0, ies: "", regional: "",
        unidade: "", email: "", telefone: "", senha: "xxxx", admin: false, equipes: {}, setores: {}
      },
      rules: {
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  watch: {
    equipeSel() {
      this.refreshKey++;
    },
    setorSel() {
      this.refreshKey++;
    }
  },
  mounted: function() {
    var self = this;
    self.loading = true;

    rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
      self.pessoas = snapshotValToArray(snapshot.val());
      self.pessoas.findID = function(id) {
        for(var key in this) {
          if(this[key].id == id) return this[key];
        }
        return undefined;
      };
      self.loading = false;
      //self.log("carregado!");
    });

    rdb.ref('/areas').on('value', function(snapshot) {
      self.areas = snapshotValToArray(snapshot.val());
      self.areasObj = snapshot.val();
      //console.log(self.areas);
    });
    rdb.ref('/usuarios/setores').on('value', function(snapshot) {
      self.setores = snapshotValToArray(snapshot.val());
      self.setoresObj = snapshot.val();
      //console.log(self.setores);
    });
    rdb.ref('/usuarios/equipes').on('value', function(snapshot) {
      self.equipes = snapshotValToArray(snapshot.val());
      self.equipesObj = snapshot.val();
      //console.log(self.equipes);
    });
    this.build();
  },
  methods:{

    build() {
      var self = this;

    },

    areaNormalizer(node) { return { id: node.id, label: node.nome } },
    setorNormalizer(node) { return { id: node.id, label: node.nome } },
    equipeNormalizer(node) { return { id: node.id, label: node.nome } },

    show() {
      this.areasSel = [];
      this.setorSel = [];
      this.equipeSel = [];
      this.dialog = true;
    },

    remove (item) {
      this.selecionado = null;
    },

    getAreasICSelecionadas() {
      var areasSel = {};
      for(var pos in this.areasSel) {
        var area = clone(this.areasObj[this.areasSel[pos]]);
        areasSel[area.id] = { id: area.id, sigla: area.sigla };
      }
      return areasSel;
    },

    adicionarProf() {
      //console.log("adicionarProf");
      if(this.formData.nome == "") {
        this.toastD("Nome obrigatório!");
        return;
      }
      if(this.formData.email == "") {
        this.toastD("Email obrigatório!");
        return;
      }
      if(!pattern.test(this.formData.email)) {
        this.toastD("Email inválido!");
        return;
      }
      this.formData.areasIC = this.getAreasICSelecionadas();
      this.formData.senha = this.formData.email.trim().substr(0,4);
      this.formData.nome = this.formData.nome.trim().toUpperCase();
      //console.log("this.formData",this.formData);
      var elem = clone2(this.formData);
      var newKey = rdb.ref('/usuarios/pessoasPendentes/').push().key;
      elem.id = newKey;
      var updates = {};
      updates["/usuarios/pessoasPendentes/"+newKey] = elem;
      //console.log(updates);
      rdb.ref().update(updates);
      this.dialog = false;
      this.dialog2 = true;
    },

  }
}

</script>

<style scoped>

</style>
