<template>
  <div class="pb-0 mb-0" v-scroll="handleScroll">

    <v-app-bar prominent height="160" flat dense shrink-on-scroll app v-c:K :color="cor.active.B" :dark="isDark"
    :src="urlCapa" :key="force">

    <template v-slot:img="{ props }">
      <div class="">
        <v-card flat v-bg:B#5 style="z-index: -1; position: absolute; top: 0px; width: 100%; height: 50px;"></v-card>
        <v-img class="" style="position: absolute; top: 0px; z-index: -2;" v-bind="props" ></v-img>
      </div>
    </template>

    <v-app-bar-nav-icon @click="HambClick"></v-app-bar-nav-icon>

    <div v-c:K class="d-none d-md-block f-raleway fw-500 fs-13pt mt-3 ms-0 ps-0">
      {{ userLog.nome }}
    </div>

    <v-btn @click="$refs.reffileupload.upLoad()" x-small v-c:K v-bg:B#6 :dark="isDark" class="f-raleway fw-300 fs-8pt fls-normal py-3 px-1 me-2" style="z-index: 5; position: absolute; bottom: 10px; right: 0px;" v-if="barHeight < 90">
      <span class="me-1" v-i:duo#image#16 v-c:K></span>
      Alterar capa
    </v-btn>

    <!-- <input type="file" ref="input1" style="display: none" @change="previewImage" accept="image/*" > -->

    <fileupload ref="reffileupload" path="/capa" @urlUpload="changeCapa" />

    <v-spacer></v-spacer>

    <v-btn v-if="isUserTemporary" class="me-2" text icon :color="cor.active.P" :dark="isDark" @click="backToAdmin">
      <span v-i:ic#logout#26 v-c:K></span>
    </v-btn>

    <notifnewuser />

    <topmenuitemnotification />
    <!-- <topmenuitemadmin /> -->
    <topmenuitemuser />

  </v-app-bar>
</div>
</template>

<script>
import { rdb, fbst } from '@/firebasedb.js'
import topmenuitemuser from "./topmenu-item-user";
import topmenuitemnotification from "./topmenu-item-notification";
import topmenuitemadmin from "./topmenu-item-admin";
import { eventBus } from "@/main.js";
import moment from 'moment';
import fileupload from '@/components/fileloading/fileupload.vue'
import notifnewuser from "@/components/notification/new-users/notif-new-user.vue";

export default {
  name: 'topmenu',
  components: { notifnewuser, topmenuitemuser, topmenuitemadmin, topmenuitemnotification, fileupload },
  props: {
  },
  data(){
    return {
      urlCapa: "",
      force: 0,
      barHeight: 0,
      navBelowFold: true,
      drawer: null,
      user: {},
      rating: 4,
      barHidden: false,
    }
  },
  watch: {
  },
  computed: {
    pageYOffset: function() {
      return window.pageYOffset;
    },
    fundo: function() {
      return {
        '--colorbg1': this.palette.B.dd,
        '--colorbg2': this.palette.B.l,
      }
    },
    moment() {
      return moment;
    }
  },
  directives: {
  },
  created() {
    //console.log("Top menu Created");
  },
  mounted() {
    var self = this;
    //console.log("Top menu Mounted")
    //self.user = self.$session.get('user');
    this.urlCapa = this.userLog.urlCapa;

    eventBus.$on("atualizaUser", function() {
      self.urlCapa = self.userLog.urlCapa;
      self.force++;
      self.$forceUpdate();
    });

    eventBus.$on("atualizaUsuario", function() {
      self.urlCapa = self.userLog.urlCapa;
      self.force++;
      self.$forceUpdate();
    });
  },
  methods: {

    teste() {
      //console.log("transitionend");
    },

    hide() {
      this.barHidden = !this.barHidden;
      if(this.barHidden) {
        this.barHeight = 0;
      } else {
        this.barHeight = 140;
      }
      console.log("hide");
    },

    handleScroll: function(event) {
      //console.log("handleScroll: ",window.pageYOffset);
      this.barHeight = window.pageYOffset;
    },

    HambClick() {
      eventBus.$emit('hamburger-click');
    },
    logout() {

    },

    click1() {
      this.$refs.input1.click()
    },

    previewImage(event) {
      var self = this;
      var uploadValue = 0;
      var imageData = event.target.files[0];
      const storageRef = fbst.ref("/capas/"+`${imageData.name}`).put(imageData);
      storageRef.on(`state_changed`,snapshot=>{
        uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        console.log("uploadValue",uploadValue);
      }, error=>{console.log(error.message)}, () => {
        uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          self.changeCapa(url);
        }); }
      );
    },

    previewImage2(event) {
      var self = this;
      var uploadValue = 0;
      var imageData = event.target.files[0];
      const storageRef = fbst.ref("/capas/"+`${imageData.name}`).put(imageData);
      storageRef.on(`state_changed`,snapshot=>{
        uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)}, () => {
        uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          self.changeCapa(url);
        }); }
      );
    },

    changeCapa(url) {
      var self = this;
      console.log("changeCapa",url);
      this.urlCapa = url;
      this.force++;
      this.$forceUpdate();
      var updates = {};
      updates["/usuarios/pessoas/"+this.userLog.id+"/urlCapa"] = url;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Capa alterada com sucesso!");
      console.log("agora já elvis!");
      this.$store.commit("updateUser", this.userLog);
      //self.$store.commit("login", { "self": self , "user": self.userLog });

    },

    backToAdmin() {
      var self = this;
      console.log("backToAdmin");
      var userAdmin = clone2(self.$store.state.lastUserAdmin);
      console.log("userAdmin",userAdmin);
      self.$store.commit("setLastUserAdmin", null);
      self.$store.commit("login", { "self": self , "user": userAdmin });
      setTimeout(function() {
        eventBus.$emit("atualizaUsuario");
      },1000);
    }

  }
}

</script>

<style scoped>

.fundoA {
  background: linear-gradient(270deg, #007f87, #1b424e, #006873);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.fundo {
  background: var(--colorbg1);  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, var(--colorbg1), var(--colorbg2));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--colorbg1), var(--colorbg2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.fundo-white2 {
  background-image: radial-gradient(circle at bottom center, var(--colorbg1),var(--colorbg1));
}

.fundoA {
  background-image: radial-gradient(circle at center center, var(--colorbg1),var(--colorbg2));
}

</style>
