<template>
  <div class="mt-3">
    <div class="p-2" v-if="users.length == 0">
      <v-alert type="info" :color="cor.active.S">
        <p class="m-0 p-0 fs-10pt">Nenhuma solicitação encontrada</p>
      </v-alert>
    </div>
    <div class="" v-for="(user,key,index) in users" :key="index">
      <v-list-item class="mb-0 pb-0 mt-n4">
        <v-list-item-avatar size="32" class="mt-0">
          <v-avatar size="32">
            <img :src="user.urlFoto" class="rounded-circle" alt="" style="">
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="text-left">
          <v-list-item-title class="">
            <p class="mb-0 fs-8pt f-roboto">
              {{user.nome}}
              <span class="text-muted fs-10pt f-roboto">
                <b-icon icon="envelope-fill" class="ms-2 me-1"></b-icon>
                {{user.email}}
              </span>
              <span class="text-muted fs-10pt f-lato">
                <b-icon icon="telephone-fill" class="ms-2 me-1"></b-icon>
                {{user.telefone}}
              </span>
            </p>
          </v-list-item-title>
          <v-list-item-subtitle>
            <p class="fs-8pt f-roboto mt-1">
              {{user.cargo}}
              <!-- <tagsarea :siglas="item.Areas"/> -->
              <span class="badge ms-2 fs-7pt f-roboto fw-400 text-dark" style="backgroundColor: #f0f0f0; vertical-align: bottom;">
                {{user.ies}} - {{user.regional}}
              </span>
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon  class="px-0 mx-0">
          <v-btn v-if="tipo=='pendente'" @click="liberar(user)" class="ps-1 mx-0 text-nonecase" :color="cor.active.V" x-small outlined>
            <i class="btn-icon-left ps-1" v-i:ic#check-circle#16></i>
            liberar
          </v-btn>
          <v-btn v-if="tipo=='pendente'" @click="naoLiberar(user)" class="ms-2 ps-1 mx-0 text-nonecase" :color="cor.active.D" x-small outlined>
            <i class="btn-icon-left ps-1" v-i:duo#x-circle#16></i>
            não liberar
          </v-btn>
          <v-btn v-if="tipo=='não liberado'" @click="liberar(user)" class="ps-1 mx-0 text-nonecase" :color="cor.active.V" x-small outlined>
            <i class="btn-icon-left ps-1" v-i:ic#check-circle#16></i>
            liberar
          </v-btn>
          <v-btn v-if="tipo=='liberado'" disabled class="ps-1 mx-0 text-nonecase" :color="cor.active.V" x-small outlined>
            <i class="btn-icon-left ps-1" v-i:ic#check-circle#16></i>
            liberar
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
      <v-divider class="mt-n4"></v-divider>
    </div>

    <dialogliberar ref="dialogliberarref" />
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import dialogliberar from "./dialog-liberar.vue";

export default {
  name: "",
  components: { dialogliberar },
  props: {
    color: { default: "#000000", type: String },
    tipo: { default: "", type: String },
    users: { default: [], type: Array },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log("tipo",this.tipo);
  },
  methods:{

    build() {
      var self = this;
    },

    naoLiberar(user) {
      console.log("naoLiberar",user);
      var updates = {};
      updates["/usuarios/pessoasPendentes/"+user.id+"/liberado"] = false;
      rdb.ref().update(updates);
      this.toastS("Usuário foi movido para lista de NÃO LIBERADOS.");
    },

    liberar(user) {
      console.log("liberar",user);
      //var updates = {};
      //updates["/usuarios/pessoasPendentes/"+user.id+"/liberado"] = true;
      //updates["/usuarios/pessoas/"+user.id] = user;
      //rdb.ref().update(updates);
      //this.toastS("Usuário LIBERADO com sucesso.");
      this.$refs.dialogliberarref.show(user);

    },

  }
}
</script>

<style scoped>
</style>
