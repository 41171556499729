
import { db } from '@/firebasedb.js'
const Handlebars = require("handlebars");
import { tmpForgotPassord2 } from "./templateForgotPassord2.js";

export const sendMail = {

  templateForgotPassord: function(dados, callback) {
    console.log("templateForgotPassord");
    console.log(Handlebars)
    var template = Handlebars.compile(tmpForgotPassord2);
    var htmlText = template(dados);
    dados.message.html = htmlText;
    db.collection('mail2').add(dados).then(() => callback('Queued email for delivery!'));
  }

}
