<template>
  <v-list-item class="mt-1 mb-2 pt-0" style="max-height: 20px;" @click="click()">
    <v-list-item-avatar>
      <img class="sz50" :src="item.urlFoto">
    </v-list-item-avatar>
    <v-list-item-content v-c:K>
      <v-list-item-title class="text-left fs-11pt">
        <span v-c:K><strong>from</strong> {{item.nomeFrom}}</span>
      </v-list-item-title>
      <v-list-item-subtitle class="text-left fs-10pt f-roboto fw-400" v-c:K>
        {{item.mensagem}}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <span class="fs-9pt f-lato fw-300" v-c:K>{{ moment(item.createdAt).fromNow() }} </span>
    </v-list-item-icon>
  </v-list-item>

</template>

<script>
//import { eventBus } from "@/main.js";
import moment from 'moment';

export default {
  name: 'topmenuitemnotificationitem',
  props: [ "item" ],
  data(){
    return {
    }
  },
  computed: {
    moment() {
      return moment;
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {

    click() {
      this.$emit("click");
    }

  }
}

</script>

<style scoped>

</style>
