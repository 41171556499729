<template>
  <main class="container-fluid" v-bg:B>

    <div class="auth-fluid" v-bg:B>
      <!--Auth fluid left content -->

      <div class="auth-fluid-form-box" v-bg:B>
        <div class="align-items-center d-flex ">

          <div class="">
            <div class="row mx-auto">
              <div class="col-12 m0 text-center" v-c:I style="">
                <div class="">
                  <img @click="clickLogin($event)" width="80%" class="rounded" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
                </div>
              </div>
            </div>
            <div class="row mt0">
              <div class="col-12 text-center mt0" style="">
                <p class="f-raleway fw-300 fs-10pt mt0 py0" v-c:K>
                  Sistema de apoio à integração curricular
                </p>
              </div>
            </div>

            <div class="row mx-auto" v-if="!forgotPass">
              <div class="col-12 text-center mt0" style="">
                <p v-c:K class="fs-15pt f-roboto fw-500 mt0 mb2 text-center">
                  Acesso
                </p>
                <p v-c:K class="fs-11pt f-raleway fs-200 mb-0 text-center">
                  Indique seu
                  <span v-if="tipoAcesso=='email'" class="fw-700">email</span>
                  <span v-if="tipoAcesso=='cpf'" class="fw-700">CPF</span>
                  e senha para acessar sua conta.
                </p>

                <v-form ref="form" v-model="valid" class="">
                  <v-card class="p-0 mt-3 mb-3">
                    <v-radio-group class="mx-auto" style="width:200px;" hide-details v-model="tipoAcesso" row>
                      <v-radio class="ms-3" value="email">
                        <template v-slot:label>
                          <span class="ms-1 fs-10pt">email</span>
                        </template>
                      </v-radio>
                      <v-radio class="ms-3" value="cpf">
                        <template v-slot:label>
                          <span class="ms-1 fs-10pt">CPF</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card>

                  <v-text-field v-if="tipoAcesso=='email'" v-c:K class="f-lato fs-12pt"  solo dense outlined label="E-mail" v-model="loginEmail" :rules="emailRules" required></v-text-field>
                  <v-text-field v-if="tipoAcesso=='cpf'" v-c:K class="f-lato fs-12pt"  solo dense outlined label="CPF" v-model="loginCpf" required></v-text-field>
                  <v-text-field v-c:K :color="cor.active.B" class="f-lato" solo dense outlined v-if="!forgotPass" label="Senha" type="password" v-model="loginSenha"></v-text-field>

                  <v-btn :disabled="!valid" v-if="!forgotPass" block class="mt1" @click="submit" outlined v-c:K :color="cor.active.K" :dark="isDark">
                    Acessar
                  </v-btn>

                </v-form>

                <p class="fs-12pt f-roboto fw-300 mt3 text-center">
                  <span class="mx-2"></span>
                  <v-btn class="fw-300" v-c:K :color="cor.active.K" :dark="isDark" small text outlined @click="esqueceuSenha">
                    Esqueci minha senha!
                  </v-btn>
                </p>
              </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->

            <div class="row mx-auto" v-if="forgotPass">
              <div class="col-12 text-center mt0" style="">

                <div class="f-raleway fs-12pt mb3" v-c:K >
                  <p class="fw-700"> Recuperação de senha </p>
                  <p class="fs-11pt fw-300" >
                    Indique o email cadastrado no sistema para que seja encaminhada instruções para seu acesso.
                  </p>
                  <div class="mt-0 text-left">
                    <v-btn :color="cor.active.I" outlined x-small @click="voltar">
                      <span class="btn-icon-left" v-i:duo#arrow-left#16></span>
                      <span class="f-raleway fs-9pt">voltar ao login</span>
                    </v-btn>
                  </div>
                </div>
                <v-form ref="form" v-model="valid" class="f-lato fs-10pt">
                  <v-text-field class="f-lato fs-12pt" :dark="isDark" solo dense outlined label="E-mail" v-model="loginEmail" :rules="emailRules" required></v-text-field>
                </v-form>
                <v-btn class="fs-8pt mt-0" small @click="submitPass" :color="cor.active.V" outlined :dark="isDark">
                  Enviar instruções de recuperação
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-divider class="my-4"></v-divider>
                  <div class="text-center">
                    <v-btn class="text-nonecase py-2" color="" outlined small @click="$refs.dialogrecuperacaoref.show()">
                      <i v-i:duo#smiley-sad#20 class="btn-icon-right"></i>
                      <span class="ms-1 fs-10pt f-raleway">
                        Desculpe, mas não sei qual meu email de acesso!
                      </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
      </div>
      <!-- end auth-fluid-form-box-->

      <div data-app>
        <dialogrecuperacao ref="dialogrecuperacaoref" />
      </div>

      <!-- Auth fluid right content -->
      <div class="auth-fluid-right text-center" v-c:K v-bg:b>
        <Particles :options="dataParticles" id="tsparticles" class="particles-img"/>
        <div class="" style="margin-top: 30px; position: absolute;">
          <div class="" v-c:K>
            <img width="80%" class="rounded" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </div>
          <p class="f-raleway fw-200 fs-12pt mt2" v-c:K>
            Sistema de apoio à integração curricular
          </p>
          <p class="fs-11pt fw-300 f-yanone">
            <strong class="mr1 fs-13pt">Versão:</strong> <span>0.1.1 ( alfa beta gama )</span>
          </p>
          <div class="mb1 mt3">
            <svg v-c:K width="260px" viewBox="0 100 210 70">
              <path d="m 19.506683,163.63042 c -4.991977,-0.96753 -8.829729,-3.60237 -10.0781133,-6.91922 -1.3935114,-3.70245 -0.1636232,-10.37971 2.3176933,-12.58305 2.567311,-2.2797 5.742082,-3.09387 12.996805,-3.33303 l 7.005321,-0.23093 -0.213163,-2.94695 c -0.262822,-3.63348 -1.161264,-4.72355 -4.566459,-5.54044 -2.459546,-0.59005 -8.140563,-0.43879 -11.833544,0.31505 -1.31497,0.26843 -1.648359,0.14641 -1.917571,-0.7018 -0.454097,-1.43074 0.83364,-4.24957 2.56356,-5.61161 1.364262,-1.07413 1.82929,-1.13166 7.768752,-0.96126 5.527408,0.15859 6.672086,0.34646 9.151444,1.50202 3.163692,1.4745 5.124066,3.69521 5.958413,6.74968 0.665938,2.43795 0.722447,19.40279 0.07394,22.19893 -0.803478,3.46434 -3.686332,6.27984 -7.543195,7.36695 -2.116199,0.59648 -9.82689,1.05557 -11.683887,0.69566 z m 9.608486,-7.52102 c 1.755232,-0.90767 2.675274,-3.48611 2.433551,-6.8201 l -0.164896,-2.27434 -3.537872,-0.16051 c -8.233478,-0.37352 -11.371731,0.99988 -11.371731,4.97665 0,1.63021 0.322056,2.40671 1.477354,3.562 1.390472,1.39047 1.718891,1.47736 5.584611,1.47736 2.669447,0 4.622459,-0.26643 5.578983,-0.76106 z" />
              <path d="m 50.700536,161.92167 c -2.19032,-1.7229 -2.492529,-3.78752 -2.294319,-15.67419 0.168814,-10.12378 0.262024,-11.03763 1.380168,-13.53235 2.387298,-5.32635 6.900217,-7.68552 14.701806,-7.68552 5.712975,0 9.093714,1.02616 11.898572,3.61158 3.786376,3.49017 4.021852,4.68429 4.021852,20.39541 v 13.64607 l -2.049045,0.15058 c -2.636954,0.19378 -4.554826,-1.25028 -5.290873,-3.98375 -0.317255,-1.17819 -0.499107,-6.13464 -0.439423,-11.97667 0.11461,-11.21857 -0.195176,-12.55882 -3.189499,-13.79913 -2.294105,-0.95029 -8.334634,-0.83573 -10.323495,0.19577 -0.869737,0.45108 -1.77947,1.22656 -2.02164,1.7233 -0.242169,0.49674 -0.554025,6.93018 -0.693006,14.29653 l -0.252709,13.39337 -2.049035,0.15058 c -1.481375,0.10886 -2.423137,-0.14368 -3.399354,-0.91158 z"/>
              <path d="m 92.576172,161.86392 c -2.676023,-2.10496 -2.817467,-3.09824 -2.817467,-19.78575 0,-11.24958 0.161434,-15.4916 0.606489,-15.93665 1.484096,-1.48409 5.970463,-0.004 7.200816,2.37462 0.688295,1.33103 0.780265,3.79911 0.665334,17.85552 l -0.133377,16.31101 -2.049036,0.15058 c -1.509771,0.11095 -2.423735,-0.14416 -3.472759,-0.96933 z" />
              <path d="m 112.39436,162.85687 c -1.38298,-0.3125 -2.82323,-1.53692 -3.5912,-3.05302 l -0.51866,-1.02396 v -11.1681 c 0,-11.7566 0.026,-12.20594 0.83743,-14.47385 0.63727,-1.78112 1.46626,-3.10954 2.74065,-4.39175 2.19855,-2.21207 4.60213,-3.23972 8.752,-3.74191 2.26196,-0.27374 2.60861,-0.27404 4.72855,-0.004 3.50908,0.44664 5.16763,1.1594 7.08608,3.04523 0.69985,0.68795 1.36302,1.41164 1.47368,1.6082 0.19439,0.34526 0.21701,0.3392 0.66666,-0.17869 1.65245,-1.90319 2.33982,-2.49016 3.73478,-3.18928 1.42622,-0.71478 2.35912,-0.97159 4.66491,-1.28417 2.0009,-0.27125 2.35626,-0.27147 4.73526,-0.003 3.1333,0.35362 3.87507,0.55389 6.23465,1.68331 1.51533,0.72531 3.72697,3.01634 4.51445,4.67651 1.5071,3.17726 1.56149,3.82126 1.56149,18.48718 v 12.39302 l -0.49138,0.39037 c -0.4391,0.34882 -0.64475,0.38105 -1.93219,0.30286 -1.94507,-0.11813 -3.09821,-0.73671 -4.1816,-2.24313 -1.10121,-1.53121 -1.07841,-1.28408 -1.07898,-11.69547 -5.2e-4,-9.67123 -0.12322,-12.13451 -0.66968,-13.44277 -0.98028,-2.34689 -2.61173,-3.13414 -6.51509,-3.14382 -4.17758,-0.0103 -5.95772,0.93672 -6.88688,3.6641 -0.28036,0.82293 -0.31456,2.10839 -0.36033,13.54218 l -0.0505,12.63294 -0.48678,0.38674 c -0.43382,0.34465 -0.64367,0.37721 -1.9285,0.29918 -1.50941,-0.0917 -1.85081,-0.2199 -3.03069,-1.13822 -0.74165,-0.57724 -1.59846,-1.94645 -1.92875,-3.08218 -0.11692,-0.40206 -0.22586,-4.76255 -0.28846,-11.54611 l -0.10059,-10.90005 -0.46687,-0.99423 c -0.57338,-1.22107 -1.49429,-2.00802 -2.90884,-2.48572 -1.39936,-0.47257 -5.41539,-0.51785 -6.89733,-0.0778 -1.93031,0.57324 -2.78723,1.42065 -3.48481,3.44617 -0.22982,0.66733 -0.26928,2.60063 -0.26928,13.19545 0,11.28551 -0.0271,12.45955 -0.29904,12.91978 -0.16446,0.27843 -0.466,0.55107 -0.67009,0.60588 -0.42472,0.11405 -2.16144,0.10265 -2.694,-0.0178 z" />
              <path d="m 180.74308,163.7701 c -2.2562,-0.25513 -4.46714,-0.74518 -5.22424,-1.15792 -0.17066,-0.093 -0.40514,-0.16916 -0.52107,-0.16916 -0.44756,0 -2.80663,-1.63646 -3.65857,-2.53789 -2.29428,-2.4276 -3.2789,-6.77433 -2.40732,-10.62736 0.90193,-3.98717 3.26235,-6.43696 7.34396,-7.62201 2.53471,-0.73592 3.64145,-0.83417 9.39692,-0.83417 h 5.6062 l 0.11406,-0.45446 c 0.21006,-0.83698 -0.0374,-4.7716 -0.34374,-5.46539 -1.17084,-2.65167 -4.19957,-3.47763 -11.13547,-3.03678 -1.80229,0.11456 -3.46593,0.28016 -3.69699,0.36801 -0.39383,0.14974 -1.49007,0.24445 -2.39337,0.20678 -0.25653,-0.0107 -0.50166,-0.21806 -0.69132,-0.58482 -0.25144,-0.48625 -0.26332,-0.70468 -0.0823,-1.51361 0.45425,-2.02979 2.01687,-4.26581 3.45536,-4.94445 0.34418,-0.16237 1.68585,-0.36074 3.34443,-0.49448 2.41878,-0.19504 3.16588,-0.18445 5.89676,0.0836 3.70867,0.36408 5.38652,0.74844 7.32627,1.67831 1.92206,0.92139 3.39061,2.21371 4.49561,3.95611 l 0.5077,0.80054 v 13.00095 c 0,9.75283 -0.0538,13.12581 -0.21553,13.50075 -0.21356,0.49529 -1.05048,1.67205 -1.56977,2.20719 -1.53231,1.57909 -4.1528,2.78255 -7.23853,3.32425 -1.38751,0.24359 -7.04618,0.45875 -8.30906,0.31595 z m 6.79021,-7.11108 c 1.88624,-0.54525 2.87898,-1.32455 3.50965,-2.75508 0.31163,-0.70683 0.57601,-5.65226 0.35025,-6.5517 l -0.11423,-0.45521 h -4.36156 c -2.39886,0 -4.99056,0.0869 -5.75934,0.19321 -3.16611,0.43763 -4.70353,2.04329 -4.70353,4.91229 0,3.00205 1.5621,4.55444 5.00331,4.97222 1.12517,0.1366 5.25268,-0.0779 6.07545,-0.31573 z"/>
              <path d="m 20.330108,115.77879 c -5.034037,-2.94606 -5.539374,-3.51901 -5.539374,-6.28057 0,-1.16839 0.120617,-2.11595 0.268034,-2.10568 0.14742,0.0103 2.42425,1.10965 5.059623,2.44308 l 4.791588,2.42442 4.714415,-2.43741 c 2.592927,-1.34057 4.819073,-2.33275 4.946989,-2.20483 0.127916,0.12792 0.232575,1.0686 0.232575,2.0904 0,1.31335 -0.208354,2.12271 -0.710931,2.76163 -0.761287,0.96783 -8.511422,5.63068 -9.336834,5.6175 -0.272204,-0.004 -2.26394,-1.04319 -4.426085,-2.30854 z"/>
            </svg>
          </div>
          <p class="mb2 mt0 f-raleway fw-200 fs-11pt">
            VPA - Vice Presidência Acadêmica
          </p>
        </div>
      </div>
      <!-- end Auth fluid right content -->
    </div>
  </main>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { sendMail } from "@/components/sendMail/sendMail.js"
import { eventBus } from "@/main.js";
import { particles } from "./particles.js";
import dialogrecuperacao from "./dialogrecuperacao.vue"

export default {
  name: 'Login',
  components: { dialogrecuperacao },
  props: {
  },
  data: () => ({
    valid: false,
    dataParticles: particles,
    loginEmail: "",
    emailRules: [
      v => !!v || 'E-mail necessário!',
      v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
    ],
    loginSenha: "",
    forgotPass: false,
    name: '',
    loginCpf: '',
    tipoAcesso: "email",
  }),
  created() {
    this.log("Login Created");
  },
  mounted() {
    var self = this;
    this.log("Login About")
  },
  methods: {

    teste() {
      this.logout();
      this.toastS('Notify text lorem ipsum');
      this.toastW('Notify text lorem ipsum');
      this.toastI('Notify text lorem ipsum');
      this.toastD('Notify text lorem ipsum');
    },

    submit() {
      var self = this;
      this.log("submit login");
      //this.log("submit loginEmail "+this.loginEmail);
      //this.log("submit loginSenha "+this.loginSenha);
      var loginField = "email";
      var loginValue = this.loginEmail;
      if(this.tipoAcesso == "cpf") {
        loginField = "cpf";
        loginValue = this.loginCpf.trim().replaceAll(".","").replaceAll("-","");
        loginValue = "" + parseInt(loginValue);
        rdb.ref('/usuarios/pessoas').orderByChild("cpf").equalTo(loginValue).once('value', function(snapshot) {
          var ret = self.snapshotValToArray(snapshot.val());
          //console.log(ret);
          if(ret.length == 0) {
            self.toastD('CPF ou senha inválidos!');
            return;
          }
          var user = ret[0];
          //console.log("user",user);
          var senha = ""+parseInt(user.cpf);
          senha = senha.substr(0,4);
          //console.log(senha);
          if(senha == self.loginSenha) {
            self.$store.commit("login", { "self": self , "user": user });
          } else {
            self.toastD('Email ou senha inválidos!');
          }
        });
        return;
      }
      this.rdb.ref('/usuarios/pessoas').orderByChild(loginField).equalTo(loginValue).once('value', function(snapshot) {
        var ret = self.snapshotValToArray(snapshot.val());
        //console.log(ret);
        if(ret.length == 0) {
          //console.log(loginValue);
          self.rdb.ref('/usuarios/pessoasEmail').orderByChild("email").equalTo(loginValue).once('value', function(snapshot) {
            var ret = snapshot.val();
            //console.log("ret",ret);
            if(ret == null) {
              if(self.tipoAcesso == "cpf") {
                self.toastD('CPF ou senha inválidos!');
              } else {
                self.toastD('Email ou senha inválidos!');
              }
              return;
            } else {
              var userID = ret[Object.keys(ret)[0]].userID;
              //console.log("ret.userID",userID);
              self.rdb.ref('/usuarios/pessoas/'+userID).once('value', function(snapshot) {
                var ret = snapshot.val();
                if(ret == null) {
                  if(self.tipoAcesso == "cpf") {
                    self.toastD('CPF ou senha inválidos!');
                  } else {
                    self.toastD('Email ou senha inválidos!');
                  }
                  return;
                } else {
                  var user = ret;
                  //console.log("submit user ");
                  //console.log("login",user);
                  //console.log("senha",user.senha);
                  if(user.senha == self.loginSenha) {
                    self.$store.commit("login", { "self": self , "user": user });
                  } else {
                    self.toastD('Email ou senha inválidos!');
                  }
                }
              });
            }
          });
        } else {
          var user = ret[0];
          //console.log("submit user ");
          //console.log("login",user);
          //self.log("submit nome " + user.nome);
          if(user.senha == self.loginSenha) {
            self.$store.commit("login", { "self": self , "user": user });
          } else {
            self.toastD('Email ou senha inválidos!');
          }
        }
      });
      //this.$session.set('login', true);
      //eventBus.$emit('login-action');
    },

    voltar() {
      this.forgotPass = false;
    },

    esqueceuSenha() {
      this.forgotPass = true;
    },

    submitPass() {
      var self = this;
      //console.log("submitPass")
      //console.log(this.loginEmail);
      if(this.loginEmail.trim().length == 0) {
        self.toastD('Email inválido!');
        return;
      }

      this.rdb.ref('/usuarios/pessoas').orderByChild("email").equalTo(this.loginEmail).once('value', function(snapshot) {
        var ret = self.snapshotValToArray(snapshot.val());
        if(ret.length == 0) {

          self.rdb.ref('/usuarios/pessoasEmail').orderByChild("email").equalTo(self.loginEmail).once('value', function(snapshot) {
            var ret = snapshot.val();
            //console.log("ret",ret);
            if(ret == null) {
              self.toastD('Email não encontrado!');
              return;
            } else {
              var userID = ret[Object.keys(ret)[0]].userID;
              //console.log("ret.userID",userID);
              self.rdb.ref('/usuarios/pessoas/'+userID).once('value', function(snapshot) {
                var ret = snapshot.val();
                console.log(ret);
                if(ret == null) {
                  self.toastD('Email não encontrado!');
                  return;
                } else {
                  //ret = ret[Object.keys(ret)[0]];
                  //console.log(ret);
                  var dados = {
                    nome: ret.nome,
                    senha: ret.senha,
                    to: self.loginEmail,
                    message: {
                      subject: 'Reenvio de senha',
                      text: 'Este é email de reenvio de senha!',
                      html: "",
                    }
                  };
                  sendMail.templateForgotPassord(dados, function() {
                    self.log("voltei");
                    self.toastS("Um email com instruções sobre recuperação de senha foi enviado!");
                    self.forgotPass = false;
                  })

                }
              });
            }
          });

        } else {
          //var senha = ret[0].senha;
          //var nome = ret[0].NOME;
          var dados = {
            nome: ret[0].nome,
            senha: ret[0].senha,
            to: self.loginEmail,
            message: {
              subject: 'Reenvio de senha',
              text: 'Este é email de reenvio de senha!',
              html: "",
            }
          };
          sendMail.templateForgotPassord(dados, function() {
            self.log("voltei");
            self.toastS("Um email com instruções sobre recuperação de senha foi enviado!");
            self.forgotPass = false;
          })
        }
      });

    },

    enviaremail() {
      var self = this;
      this.log("enviaremail")
      var dados = {
        nome: "Marcelo Amorim",
        senha: "ALGUMASENHA",
        to: 'marc.m.amorim@gmail.com',
        message: {
          subject: 'Reenvio de senha',
          text: 'This is the plaintext section of the email body.',
          html: "",
        }
      };
      sendMail.templateForgotPassord(dados, function() {
        self.log("voltei");
      })
    },

    linkedin() {
      console.log("linkedin");
      console.log(this.$auth);
      this.authenticate();
    },

    authenticate: function () {
      console.log(this.$auth.options.providers.linkedin);
      this.$auth.authenticate("linkedin").then(function () {
        // Execute application logic after successful social authentication
        //console.log("respondeu aqui");
        console.log(this.$auth.options.providers.linkedin);
      })
    },

    loginAuth: function () {
      this.$auth.login({ email, password }).then(function () {
        // Execute application logic after successful login
      })
    },

    registerAuth: function () {
      this.$auth.register({ name, email, password }).then(function () {
        // Execute application logic after successful registration
      })
    },

    clickLogin(e) {
      var self = this;
      //console.log("e",e);
      if(e.shiftKey && e.ctrlKey) {
        console.log("Bem-vindo Marcelo!");
        self.rdb.ref('/usuarios/pessoas/-Md8DWM9iLgL-a2dfCmR').once("value",function(snapshot) {
          var user = snapshot.val();
          self.$store.commit("login", { "self": self , "user": user });
        });
      } else {
        console.log("Ohhh no!");
      }
    }
  }
}

</script>

<style scoped>
@import "./login.css"

</style>
