<template>
  <span v-if="isUserAdmin">
    <v-btn icon class="mt0" @click="$refs.newusersref.show()">
      <v-btn icon class="me-2"  v-if="qtde == 0">
        <span v-i:ic#person#26 v-c:B></span>
      </v-btn>
      <v-badge overlap color="red" v-if="qtde > 0">
        <template v-slot:badge>
            <span class="fs-10pt f-sanspro fw-600">{{qtde}}</span>
        </template>
        <span v-i:ic#person#26 v-c:B></span>
      </v-badge>
    </v-btn>
    <newusers ref="newusersref" :solicitacoes="solicitacoes" />
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import moment from 'moment';
import newusers from './new-users.vue';

export default {
  name: 'notifnewuser',
  components: { newusers },
  props: { },
  data(){
    return {
      solicitacoes: [],
      qtde: 0,
    }
  },
  created() {
  },
  mounted() {
    var self = this;
    //console.log("Top menu notifnewuser Mounted")
    var userID = this.userLog.id;
    rdb.ref('/usuarios/pessoasPendentes').on('value', function(snapshot) {
      self.solicitacoes = snapshotValToArray(snapshot.val(),"createdAt",true);
      self.qtde = 0;
      for(var key in self.solicitacoes) {
        if(self.solicitacoes[key].liberado == undefined) {
          self.qtde++;
        }
      }
      //console.log("self.solicitacoes",self.solicitacoes);
    });

  },
  methods: {

  }
}

</script>

<style scoped>
</style>
