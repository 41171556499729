
export const menuItens = [
  { title: "GERAL", type: "header" },
  { title: "Home", collection: "ic", icon: "home", link: "Home" },
  { title: "Meu perfil", collection: "duo", icon: "user", link: "profile" },
  { title: "Minhas Encomendas A1/A2", collection: "ic", icon: "fact-check", link: "profile" },
  { title: "Adm. Sistema", collection: "duo", icon: "gear", link: "admin", equipe: "Administradores AppIntegra" },
  { title: "ÁREAS VPA", type: "header" },
  { title: "Formação & Engajamento do professor", collection: "duo", icon: "chalkboard-teacher", link: "formacaodocente" }, // equipe: "Administradores Formação Docente" },
  { title: "Calendário", collection: "duo", icon: "calendar", link: "calendario" },
  { title: "Live", collection: "duo", icon: "play", link: "live" },
  { title: "Avaliação", collection: "ic", icon: "fact-check", link: "avaliacao" },
  //{ title: "FORMAÇÃO PLANOS DE ENSINO", type: "header", equipe: "Formação Planos de Ensino" },
  //{ title: "Margarida", sigla: "MARG", collection: "ar", icon: "margarida", equipe: "Formação Planos de Ensino", link: "formacoes" },
  //{ title: "Violeta", sigla: "VIOL", collection: "ar", icon: "margarida", equipe: "Formação Planos de Ensino", link: "formacoes" },
  //{ title: "Rosa", sigla: "ROSA", collection: "ar", icon: "margarida", equipe: "Formação Planos de Ensino", link: "formacoes" },
  //{ title: "Lírio", sigla: "LIR", collection: "ar", icon: "margarida", equipe: "Formação Planos de Ensino", link: "formacoes" },
  //{ title: "Gérbera", sigla: "GERB", collection: "ar", icon: "margarida", equipe: "Formação Planos de Ensino", link: "formacoes" },
  { title: "ÁREAS DE CONHECIMENTO", type: "header" },
  { title: "Arquitetura e Urb. & Design", sigla: "AU&D", collection: "ar", icon: "arquitetura2", link: "formacoes" },
  { title: "C. Agrárias & Meio Ambiente", sigla: "CA&MA", collection: "ar", icon: "agraria2", link: "formacoes" },
  { title: "Ciências Biológicas e Saúde", sigla: "CB&S", collection: "ar", icon: "saude", link: "formacoes" },
  { title: "Ciências Humanas",  sigla: "CH",collection: "ar", icon: "humanas", link: "formacoes" },
  { title: "Ciências Jurídicas",  sigla: "CJ", collection: "ar", icon: "balance2", link: "formacoes" },
  { title: "Comunicação & Artes",  sigla: "C&A", collection: "ar", icon: "comunicacao", link: "formacoes" },
  { title: "Engenharias", sigla: "ENG", collection: "ar", icon: "engineering", link: "formacoes" },
  { title: "Gestão & Negócios", sigla: "G&N", collection: "ar", icon: "gestao", link: "formacoes" },
  { title: "TI & Computação", sigla: "TI&C", collection: "ar", icon: "tic", link: "formacoes" },
  { title: "Turismo & Hospitalidade", sigla: "T&H", collection: "ar", icon: "turismo", link: "formacoes" },
  { title: "Core Curriculum", sigla: "CORE", collection: "ar", icon: "core", link: "formacoes" },
  //{ title: "Temas", collection: "duo", icon: "aperture", link: "tema" },
  //{ title: "Lab 01", collection: "duo", icon: "aperture", link: "lab01" },
  //{ title: "Lab 02", collection: "duo", icon: "aperture", link: "lab02" },
  //{ title: "Icones", collection: "duo", icon: "aperture", link: "lab03" },
];
