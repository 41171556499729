<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mt0" v-bind="attrs" v-on="on">
        <v-btn icon class="me-2"  v-if="notifications.length == 0">
          <span v-i:duo#bell#26 v-c:B></span>
        </v-btn>
        <v-badge overlap color="red" :content="notifications.length" v-if="notifications.length > 0">
          <span v-i:duo#bell#26 v-c:B></span>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="mt-0 mb-0 pt-0" v-bg:B>
      <v-list class="mt-0 mb-0 pt-0" v-bg:B v-c:K>
        <v-subheader v-c:K class="mb-0 pb-0 fs-10pt f-montserrat fw-600" style="max-height: 30px;">
          Não lidos
        </v-subheader>
        <hr class="mt-0 mb-1 pt-0 mx-3" :style="'border-color: '+cor.active.b">
        <div v-c:K class="card py-3 mx-2 px-1 fs-10pt f-lato fw-300" v-if="notifications.length == 0">
          Nenhuma notificação encontrada!
        </div>
        <div v-c:K class="" v-for="(item, index) in notifications" :key="index">
          <topmenuitemnotificationitem :item="item" @click="itemUnreadClick(item.id)"/>
          <v-divider class="mt-0 mb-0 pt-0 pb-0"/>
        </div>
      </v-list>

      <v-list class="mt-n2 mb-0 pt-0" v-bg:B v-c:K v-if="notificationsReaded.length != 0">
        <v-subheader v-c:K class="mb-0 pb-0 fs-10pt f-montserrat fw-600" style="max-height: 30px;">
          Lidos
        </v-subheader>
        <hr class="mt-0 mb-1 pt-0 mx-3" :style="'border-color: '+cor.active.b">
        <div v-c:K class="card py-3 mx-2 px-1 fs-10pt f-lato fw-300" v-if="notificationsReaded.length == 0">
          Nenhuma notificação encontrada!
        </div>
        <div v-c:K class="" v-for="(item, index) in notificationsReaded" :key="index">
          <topmenuitemnotificationitem :item="item"/>
          <v-divider :color="cor.active.b" class="mt-0 mb-0 pt-0 pb-0"/>
        </div>
      </v-list>

      <div v-c:K class="text-center mb-0 pb-2 fs-10pt f-montserrat fw-500">
        Ver todos
      </div>

    </v-card>
  </v-menu>

</template>

<script>
//import { rdb, snapshotValToArraySort } from '@/firebase.js'
//import { eventBus } from "@/main.js";
import topmenuitemnotificationitem from "./topmenu-item-notification-item"
import moment from 'moment';

export default {
  name: 'topmenuitemnotification',
  components: { topmenuitemnotificationitem },
  props: { },
  data(){
    return {
      user: {},
      notifications: [],
      notificationsReaded: [],
    }
  },
  created() {
    //console.log("Top menu item notification Created");
  },
  mounted() {
    var self = this;
    //console.log("Top menu item notification Mounted")
    var userID = this.userLog.id;
    this.rdb.ref('/notification').orderByChild("toUserID").equalTo(userID).on('value', function(snapshot) {
      var ret = self.snapshotValToArraySort(snapshot.val(),"createdAt",true);
      //console.log(ret);
      self.notifications = [];
      self.notificationsReaded = [];
      for(var i=0; i < ret.length; i++) {
        if(i<2) {
          ret[i].readed = false;
        }
        if(ret[i].readed) {
          if(self.notificationsReaded.length < 3) {
            self.notificationsReaded.push(ret[i]);
          }
        } else {
          self.notifications.push(ret[i]);
        }
      }
    });

  },
  methods: {

    updateKey(path, newValue, callback) {
      //console.log("updateKey path: "+path, newValue);
      var updates = {};
      updates[path] = newValue;
      this.rdb.ref().update(updates);
      callback();
    },

    itemUnreadClick(itemId) {
      //console.log(itemId);
      this.updateKey("/notification/"+itemId+"/readed",true, function() {
        //console.log("alterado");
      });
    }

  }
}

</script>

<style scoped>
.shadow {
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.3), 2px 6px 20px 2px rgba(0, 0, 0, 0.19);
}
</style>
