<template>
  <span>
    <v-dialog v-model="dialog" width="800">
      <v-card v-bg:b class="pb-3">
        <dialogtoolbar @close="dialog = false" />
        <v-row class="mx-1 mt-1">
          <v-col cols="12" class="">
            <v-card v-bg:B>
              <v-tabs class="mt-1" height="28" v-model="tab" background-color="transparent" :color="cor.active.P">
                <v-tab class="mt-0 px-2 me-1" style="min-width: 0px;" v-for="(item,index) in ['Pendentes','Não liberados', 'liberados']" :key="index">
                  <template slot:default class="px-0" >
                    <span class="text-nonecase f-raleway fs-11pt fw-600">{{item}}</span>
                  </template>
                </v-tab>
              </v-tabs>
            </v-card>
            <v-tabs-items v-bg:b v-model="tab">
              <v-tab-item>
                <v-card outlined elevation="4" class="mt-4" min-height="200">
                  <userslist :users="pendentes" tipo="pendente"/>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card outlined elevation="4" class="mt-4" min-height="200">
                  <userslist :users="naoliberados" tipo="não liberado"/>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card outlined elevation="4" class="mt-4" min-height="200">
                  <userslist :users="liberados" tipo="liberado"/>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import dialogtoolbar from "./dialogToolBar.vue";
import userslist from "./userslist.vue";

export default {
  name: "newusers",
  components: { dialogtoolbar, userslist },
  props: {
    color: { default: "#000000", type: String },
    solicitacoes: { default: [], type: Array },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      tab: null,
      pendentes: [],
      naoliberados: [],
      liberados: [],
    }
  },
  watch: {
    solicitacoes() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
      //console.log("this.solicitacoes",this.solicitacoes);
      this.pendentes = [];
      this.naoliberados = [];
      this.liberados = [];
      for(var key in this.solicitacoes) {
        var item = this.solicitacoes[key];
        if(item.liberado == undefined) {
          this.pendentes.push(item);
        } else {
          if(item.liberado) {
            this.liberados.push(item);
          } else {
            this.naoliberados.push(item);
          }
        }
      }
    },

    show() {
      //console.log("show");
      this.dialog = true;
    },

  }
}
</script>

<style scoped>
</style>
