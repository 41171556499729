import { render, staticRenderFns } from "./topmenu-item-notification.vue?vue&type=template&id=1fcd7d74&scoped=true&"
import script from "./topmenu-item-notification.vue?vue&type=script&lang=js&"
export * from "./topmenu-item-notification.vue?vue&type=script&lang=js&"
import style0 from "./topmenu-item-notification.vue?vue&type=style&index=0&id=1fcd7d74&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fcd7d74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBadge,VBtn,VCard,VDivider,VList,VMenu,VSubheader})
