<template>
  <div id="app" v-bg:b>
    <login v-if="!isLog"/>
    <v-app v-if="isLog && isAdminRecursosLoad" v-bg:b>
      <sidemenu />
      <topmenu />
      <v-sheet v-bg:b>
        <v-main v-bg:b>
          <v-container fluid class="mx-0 px-3">
            <router-view/>
          </v-container>
          <v-footer height="30" :color="hexShades(cor.active.b,0.025)" :dark="isDark" outlined app>
            <v-col class="text-center p0 m0" cols="12">
              <img width="120px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
              <span v-c:P class="mt-n2 f-raleway fs-12pt fw-200"> - Sistema de apoio à integração curricular</span>
            </v-col>
          </v-footer>
        </v-main>
      </v-sheet>
    </v-app>

    <appoverlay :visible="overlay" />

  </div>
</template>

<script>
import topmenu from "@/components/frontlayout/topmenu/top-menu.vue";
import sidemenu from "@/components/frontlayout/side-menu.vue";
import login from "@/views/Login/login";
import appoverlay from "@/components/appoverlay.vue"
import { userconnected } from "@/views/Login/user-connected.js"
import moment from "moment";

export default {
  name: 'App',
  components: { login, topmenu, sidemenu, appoverlay },
  data: () => ({
    overlay: true,
  }),
  watch:{
    $route (to, from){
      console.log("route changed");
      console.log(this.$route.name);
      if(this.$route.name == "linkedinauth") {
        this.$store.commit("loginLinkedin");
      }
    }
  },
  computed: {
    fundo: function() {
      return {
        '--colorbg1': this.palette.B.ll,
        '--colorbg2': this.palette.B.dd,
      }
    }
  },
  created() {
    var self = this;
    this.log("App Created");
    this.$store.commit("initAreaColors");
    this.loginFromSession(function() {
      self.overlay = false;
    });
    userconnected(self,30000,function() {
      if(self.isLog) {
        self.rdb.ref("/usuarios/acessos/userLogControl/"+self.userLog.id+"/logged").set(true);
        self.rdb.ref("/usuarios/acessos/userLogControl/"+self.userLog.id+"/last").set(moment().format());
      }
    });
    //console.log("oi");
  },
  mounted() {
    var self = this;
    this.log("App Mounted")


    this.$store.commit("loadAdminRecursos");
  },
  methods: {

    beforeWindowUnload() {
      alert("oi");
    },

    handleScroll() {
      //console.log("handleScroll");
    }
  }
};
</script>

<style scoped>

.fundo {
  background: var(--colorbg1);  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, var(--colorbg1), var(--colorbg2));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--colorbg1), var(--colorbg2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.algo {
  color: red;
}

.fundoA {
  background: linear-gradient(270deg, #007f87, #1b424e, #006873);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

</style>
