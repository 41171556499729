<template>
  <v-navigation-drawer v-model="drawer" app width="220" :dark="isDark" :color="hexShades(cor.active.B)">
    <div class="row">
      <div class="col-12 mb0 pb0">
        <div style="width: 90%" class="mx-auto mt-4 mb-0 p-0" v-c:I>
          <div class="text-center">
            <img width="90%" class="rounded" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </div>
        </div>
      </div>
      <div class="col-12 mt0 mb0 pb1 pt1 text-center">
        <p class="f-ralaway fw-300 fs-8pt " v-c:K>
          Sistema de apoio à integração curricular
        </p>
      </div>
    </div>
    <v-divider v-bg:B></v-divider>
    <div dense class="">
      <v-list-item-group v-model="selectedItem" :key="refresh" class="">
        <span v-for="(item,index) in menuItens" :key="index">
          <div v-if="item.type == 'header'" class="f-raleway fs-8pt fw-700 mt1 ml1" v-c:P>{{item.title}}</div>
          <v-list-item v-if="item.type != 'header'" :dark="isDark" @click="goto(item)">
            <div class="ml1">
              <div :dark="isDark" :color="item.cor" class="truncate align-middle fs-11pt fw-500 f-raleway" v-if="selectedItem == index">
                <span class="align-middle" :dark="isDark">
                  <span v-if="item.collection == 'duo'">
                    <span v-i:duo##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" v-if="selectedItem == index"></span>
                    <span v-i:duo##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                  <span v-if="item.collection == 'ic'">
                    <span v-i:ic##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" v-if="selectedItem == index"></span>
                    <span v-i:ic##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                  <span v-if="item.collection == 'ar'">
                    <span v-i:ar##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" v-if="selectedItem == index"></span>
                    <span v-i:ar##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                </span>
                <span class="ml1 align-bottom fs-9pt">{{item.title}}</span>
              </div>
              <div :dark="isDark" :color="item.cor" class="truncate fs-9pt f-raleway fw-300" v-if="selectedItem != index">
                <span class="align-middle" :dark="isDark">
                  <span v-if="item.collection == 'duo'">
                    <span v-i:duo##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem == index"></span>
                    <span v-i:duo##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                  <span v-if="item.collection == 'ic'">
                    <span v-i:ic##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem == index"></span>
                    <span v-i:ic##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                  <span v-if="item.collection == 'ar'">
                    <span v-i:ar##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem == index"></span>
                    <span v-i:ar##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="" v-if="selectedItem != index"/>
                  </span>
                </span>
                <span class="ml1 align-bottom">{{item.title}}</span>
              </div>
            </div>
          </v-list-item>
        </span>
      </v-list-item-group>

    </div>

  </v-navigation-drawer>
</template>

<script>
//import { rdb, snapshotValToArray } from '@/firebase.js'
import { eventBus } from "@/main.js";
import { menuItens } from "./menuitens.js";

export default {
  name: 'SideMenu',
  props: {},
  data(){
    return {
      drawer: null,
      refresh: 0,
      areas: [],
      selectedItem: 0,
      menuItens: menuItens,
    }
  },
  computed: {
    itemhover: function() {
      return {
        '--normal-color': this.cor.active.K,
        '--normal-colorbg': this.cor.active.B,
        '--hover-color': this.hexTints(this.cor.active.P),
        '--active-color': this.cor.active.P,
        '--active-colorbg': this.cor.active.B,
      }
    },
    itemactive: function() {
      return {
        '--normal-color': this.cor.active.K,
        '--normal-colorbg': this.cor.active.B,
        '--active-color': this.cor.active.P,
        '--active-colorbg': this.cor.active.B,
      }
    },
    fundo: function() {
      return {
        '--colorbg1': this.cor.active.b,
        '--colorbg2': this.cor.active.b,
      }
    }
  },
  created() {
    //console.log("Side Menu Created");
  },
  mounted() {
    var self = this;
    //console.log("Side Menu Mounted")
    eventBus.$on("hamburger-click", function() {
      self.drawer = !self.drawer;
    });
    eventBus.$on("atualizaUsuario", function() {
      //console.log("sidemenu atualizaUsuario");
      //console.log("self.$store.state.admin",self.$store.state.admin);
      //console.log("self.userLog",self.userLog);
      self.build();
    });
    this.build();
  },
  methods: {

    build() {
      var self = this;
      //console.log("Side Menu Build")

      this.rdb.ref('/curriculo/areas').on('value', function(snapshot) {
        var ret = self.snapshotValToArray(snapshot.val());
        console.log("areas",ret);
        self.menuItens = clone(menuItens);
        //console.log("self.menuItens ANTES",self.menuItens);
        for(var i=0; i< ret.length; i++) {
          for(var k=0; k<self.menuItens.length; k++) {
            if(self.menuItens[k].equipe != undefined) {
              var equipe = self.$store.state.geral.admin.equipesByName[self.menuItens[k].equipe];
              if(equipe != undefined) {
                if(ret[i].sigla == self.menuItens[k].sigla) {
                  self.menuItens[k].cor = ret[i].cor;
                  self.menuItens[k].pathArea = "/area/"+ret[i].id;
                }
              } else {
                //console.log("self.menuItens[k]",self.menuItens[k]);
                //console.log("self.menuItens[k].equipe",self.menuItens[k].equipe);
              }
            } else {
              if(ret[i].sigla == self.menuItens[k].sigla) {
                self.menuItens[k].cor = ret[i].cor;
                self.menuItens[k].pathArea = "/area/"+ret[i].id;
              }
            }
          }
        }
        self.areas = ret;
        self.$forceUpdate();
        var menus = [];
        for(var idx in self.menuItens) {
          if(self.menuItens[idx].equipe != undefined) {
            //console.log(self.menuItens[idx]);
            //console.log(self.userLog);
            //console.log(self.$store.state.admin.equipesByName[self.menuItens[idx].equipe]);
            var equipe = self.$store.state.geral.admin.equipesByName[self.menuItens[idx].equipe];
            if(equipe == undefined) {
              //self.menuItens.splice(idx, 1);
              console.log("sem equipe",self.menuItens[idx]);
            } else {
              if(equipe.pessoas[self.userLog.id] == undefined) {
                //self.menuItens.splice(idx, 1);
              } else {
                menus.push(self.menuItens[idx]);
              }
            }
          } else {
            menus.push(self.menuItens[idx]);
          }
        }
        self.menuItens = menus;
        console.log("self.menuItens DEPOIS",self.menuItens);
        self.refresh++;
        self.$forceUpdate();
      });
    },

    goto(item) {
      //console.log(item.link);
      //console.log(item.pathArea);
      if(item.pathArea != undefined) {
        this.$router.push( { path: item.pathArea });
      } else {
        this.$router.push( { name: item.link });
      }
    }

  }
}

</script>

<style scoped>

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  opacity: 0;
}

.theme--light.v-list-item:before, .theme--light.v-list-item:hover:before, .theme--light.v-list-item:focus:before {
  opacity: 0;
}

.theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: 0;
}

.theme--dark.v-list-item:before, .theme--dark.v-list-item:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: 0;
}

.v-list-item {
  padding: 0px !important;
  min-height: 40px;
}

.itemactive {
  background: var(--active-colorbg) !important;
  color: var(--active-color) !important;
  fill: var(--active-color) !important;
  width: 100% !important;
}
.itemhover {
  color: var(--normal-color) !important;
  fill: var(--normal-color) !important;
  width: 100% !important;
}
.itemhover:hover .itemhover, .itemhover.hover .itemhover {
  color: var(--hover-color) !important;
  fill: var(--hover-color) !important;
  width: 100% !important;
}

</style>
