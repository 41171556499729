import { render, staticRenderFns } from "./notif-new-user.vue?vue&type=template&id=e548f414&scoped=true&"
import script from "./notif-new-user.vue?vue&type=script&lang=js&"
export * from "./notif-new-user.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e548f414",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBadge,VBtn})
