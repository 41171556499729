
var interval = null;
export const userconnected = function(self,time,func) {
  if(interval != null) { clearInterval(interval); }
  var c = (new Date()).getMilliseconds();
  interval = setInterval(function() {
    console.log("["+c+"] userconnected");
    self.eventBus.$emit("refreshUsersOnline");
    func(self);
  }, time);
}
